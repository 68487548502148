<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Switches from "vue-switches";

import {
    planMethods,
} from "@/state/helpers";

/**
 * Client Plans component
 */
export default {
  page: {
    title: "Planos do Sistema",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Switches
  },
  data() {
    return {
      title: "Planos do Cliente",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Planos do Sistema",
          active: true,
        },
      ],      
      loading: true,
      plans: [],
      editingPlan: {}
    }
  },
  created() {
    this.loadAll();
  },
  methods: {    
    ...planMethods,
    
    async loadAll() {
      this.loading = true;      
      const registros = await this.getAll();
      this.plans = registros.data;
      this.loading = false;
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">ATRIBUIR PLANOS PARA O CLIENTE</h4>
            <p class="card-title-desc">
              <strong>Observação:</strong> Você pode alternar o botão para ativar ou desativar um recurso para acessar.
            </p>

            <div class="table-responsive mb-0"> 
              <b-spinner
                v-if="loading"
                label="Spinning"
                variant="info"
                class="m-1"
              ></b-spinner>             
              <div
                v-if="!loading">
                <table class="table mb-0">
                  <thead>
                    <tr>
                      <th>Plano</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="table-info" v-for="plan in this.plans" :key="plan.id">
                      <td>{{ plan.name }}</td>
                      <td>
                        <switches v-model="enabled1" type-bold="false" :color="!plan.status ? 'danger' : 'primary'" class="ml-1 mb-0"></switches>  
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
